.section {
    background-color: #EFEFEF;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.sectionWeb {
    display: none;
}

.section h3 {
    font-weight: bold;
    font-size: 24px;
    color: #67A23A;
}

.imgCont {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 1em 0; */
}

.imgCont img {
    max-width: 100%;
    width: 55%;
}

.modeloData {
    padding: 1em 0;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* gap: 1em; */
}

.dataRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dataTitle {
    font-size: 10px;
}

.dataValue {
    text-align: center;
    width: 100%;
    font-size: 10px;
    font-weight: bold;
    border: 1px solid #67A23A;
    padding: 0.2em;
}

.sectionCracteristicas {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.titleCont {
    width: 100%;
    background-color: #67A23A;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sectionCracteristicas h3 {
    width: 90%;
    color: white;
    text-transform: uppercase;
    text-align: start;
    font-size: 18px;
    font-weight: bold;
}

.row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    background-color: #FFFFFF;
    border: 0.5px solid #67A23A;
    padding: 0.2em 0;
}

.rowInfoCont {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: start;
    gap: 50px;
    width: 90%;
    font-size: 10px;
}

.rowInfoCont span {
    width: 100%;
}

.descargarFichaContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    padding: 1em 0;
}

.descargar {
    width: 100%;
    color: #67A23A;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
}

.volver {
    font-size: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}



@media (min-width: 768px) {
    .section {
        display: none;
    }

    .sectionWeb {
        background-color: #EFEFEF;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .sectionWeb h3 {
        font-weight: bold;
        font-size: 54px;
        color: #67A23A;
        justify-self: flex-start;
        text-align: start;
        width: 100%;

    }

    .sectionCracteristicas {
        width: 85%;
    }

    .sectionCracteristicas h3 {
        width: 90%;
        color: white;
        text-transform: uppercase;
        text-align: start;
    }

    .titleCont h3 {
        font-size: 24px;
    }

    .portada {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .portadaModeloDataCont {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        text-align: start;
    }

    .portadaModeloDataCont h3 {
        margin: 0;
    }

    .modeloData {
        width: 95%;
        align-self: flex-start;
        justify-content: space-between;
        /* gap: 100px; */
    }

    .portada {
        width: 85%;
    }

    .portada .imgCont {
        width: 50%;
        width: auto;
    }

    .portada .imgCont img {
        width: auto;
    }

    .dataTitle {
        font-size: 24px;
    }

    .dataValue {
        font-weight: bold;
        box-sizing: border-box;
        font-size: 32px;
        padding: 0 1em;
        background-color: #FFFFFF;
    }

    .dataCont {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        /* background-color: red; */
        border: 0.5px solid #67A23A;
        box-sizing: border-box;

    }

    .dataCol {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }

    .row {
        width: 100%;
        border: none;
        border-bottom: 0.5px solid #67A23A;
        border-top: 0.5px solid #67A23A;
    }

    .rowInfoCont {
        min-height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0;
        /* padding-left: 20px; */
    }

    .rowInfoCont span {
        width: 100%;
        font-size: 21px;
        padding-left: 1em;
    }

    .descargarFichaContainer {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        padding: 1em 0;
    }

    .descargar {
        width: 100%;
        color: #67A23A;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: bold;
        cursor: pointer;
    }

    .volver {
        cursor: pointer;
        width: 100%;
        font-size: 24px;
        justify-content: flex-end;
    }
}


@media only screen and (min-width: 768px) and (max-width: 1500px) {
    .dataTitle {
        font-size: 18px;
    }

    .dataValue {
        font-size: 22px;
    }

    .rowInfoCont {
        min-height: 60px;
    }
}