.section {
    background-color: #EFEFEF;
    width: 100%;
    gap: 1.5em;
    margin: 2em 0;
    padding: 1em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2em 0;
}

.container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2em;
}

.card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imgCont {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imgCont img {
    max-width: 100%;
}

.textCont {
    text-align: center;
    width: 90%;
    font-size: 14px;
    font-weight: bold;
    font-weight: 600;
    text-transform: uppercase;
}

.sectionDescripcion {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.absolu {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
    font-size: 18px;
    background: radial-gradient(circle at center, #33333334 30%, #0000008a 80%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.paragraphCont {
    width: 90%;
    font-size: 12px;
}

.subTitulo {
    font-size: 12px;
    font-weight: bold;
}

.infoContainer h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}


.videoCont {
    padding: 1em 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.videoCont h3 {
    font-size: 18px;
    font-weight: bold;
}

.baudouin {
    width: 90%;
}

.sectionDescripcion .imgCont img {
    width: 100%;
}

.baudouin h3 {
    font-weight: bold;
    font-size: 18px;
}

.baudouin p {
    font-size: 12px;
}


.baifaImgWeb {
    display: none;
}

@media (min-width: 768px) {
    .baifaImg {
        display: none;
    }

    .baifaImgWeb {
        display: block;
    }

    .container {
        flex-direction: row;
        padding: 5em 0;
    }

    .card {
        gap: 1.2em;
        min-height: 200px;
    }

    .textCont {
        font-size: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-transform:none;
    }

    .card .imgCont img {
        width: 20%;
    }

    .sectionDescripcion {
        /* padding: 2em 0 5em 0; */
        padding: 0;
        padding-bottom: 2em;
    }

    .absolu {
        font-size: 32px;
    }

    .paragraphCont {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        font-size: 18px;
        gap: 1em;
        padding: 2em 0;
    }

    .paraCol {
        width: 90%;
    }

    .subTitulo {
        font-size: 18px;
    }

    .videoCont h3 {
        font-size: 32px;
    }

    .iframe {
        width: 90%;
    }

    .baudouin {
        padding: 5em 0;
        padding-top: 5em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        gap: 60px;
    }

    .baudouinInfoCont {
        width: 100%;
    }

    .baudouin h3 {
        font-size: 32px;
    }

    .baudouin p {
        font-size: 18px;
    }

    .baudouin .imgCont {
        justify-content: flex-start;
        width: auto;
        width: 50%;
    }

    .baudouin .imgCont img {
        width: 100%;
        /* background-color: red; */
    }
}