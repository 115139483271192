footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px 2px grey;
  padding: 1em 0;
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  position: relative;
}
.filtro {
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000a3;
}
.footerContainer { 
    width: 80%;
    display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: 1em;
}
.info p{
  margin: 0;
}

footer .text {
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
}


.info p:nth-child(2) {
  color: #000000;
  font-size: 18px;
}

.info p:nth-child(3) {
  color: #000000;
  font-size: 18px;

}

.numero {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  font-size: 18px;
}

.numero span {
  width: 100%;
  color: #000000;
  /* font-family: "Segoe UI", "Open Sans"; */
  font-size: 18px;
}

.wspContainer {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 10%;
}

.wspContainer img {
  width: 100%;
}

.redes {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  gap: 1em;
  /* justify-content: space-between;
  align-items: start; */
}
.follow {
  font-weight: bold;
  font-size: 22px;
  margin: 0;
  padding: 0;
}

.redesContainer {
  display: flex;
  align-items: center;
  gap: 1em;
  width: 100%;
  justify-content: space-between;
}

.redesContainer div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ffffff;
  border: 0.5px solid #edf0f2;
  box-shadow: 0px 0px 5px #6e6e6e;
  cursor: pointer;
  width: 37%;
  height: 50%;
  padding: 1em;
}
.redesContainer div a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.redesContainer img {
  width: 60%;
}

.redesContainer div:hover {
  box-shadow: 0px 0px 10px #6e6e6e;
}

.redes .text {
  display: none;
}

.icon {
  font-size: 40px;
}

/* MOBIULe */
@media only screen and (max-width: 640px) {

  .footerContainer {
    flex-direction: column;
    gap: 1.5em;
  }
  .redesContainer {
    width: 100%;
    justify-content: space-between;
  }

  .follow {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }


  footer .text {
    font-size: 18px;
  }

  .numero span {
    font-size: 14px;
  }
  .info p:nth-child(2) {
    font-size: 14px;
  }

  .info p:nth-child(3) {
    font-size: 14px;
  }

  .redesContainer div {
    width: 30%;
    height: 50%;
  }

  .icon {
    font-size: 30px;
  }

}


