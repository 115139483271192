.container {
    padding-top: 4em;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.tituloText {
    width: 90%;
    text-align: start;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 26px;
    border: none;
    padding: 0;
    margin: 0;
    color: #666666;
    font-weight: 500;
}


@media (max-width: 768px) {

    .container {
        box-shadow: 0 0 10px 10px #ffffff;
        height: auto;
        gap: 1em;
        padding: 0;
        margin: 0;
    }
    .tituloText {
        font-size: 0.93rem;
    }
    

}