.section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accordion {
  cursor: pointer;
  width: 90%;
  box-shadow: none;
  border: none;
  padding: 0;
  margin: 0;
}

.accordionHeader {
  font-size: 0.7rem;
}

.accordionContent {
  font-size: 0.5rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.accordionContent {
  max-height: 200px; /* Altura máxima del contenido que desees mostrar */
}


.tituloText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 26px;
  font-weight: 500;
  border: none;
  padding: 0;
  margin: 0;
  color: #666666;
  width: 100%;
  /* color: #7070709d; */
}
.txtCont {
  width: 100%;
}
.txtCont div {
  padding: 1em 0;
}
.texto {
  animation: fadeIn 1s ease-in;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 19px;
  font-weight: bold;
  border: none;
  margin: 0;
  width: 100%;
  padding: 1em 0;
  margin: 1em 0;
  text-transform: uppercase;
  /* background-color: red; */
}
.texto:hover {
  color: #666666;
}

/* ANIMATIONS  */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .tituloText {
      font-size: 0.93rem;
      padding-right: 5em;
    }
  .texto {
    font-size: 0.75rem;
    
  }

}
