.section {
    width: 90%;
    /* background-color: red; */
    padding-bottom: 1.2em;
}

.section h3 {
    font-weight: bold;
    font-size: 18px;
    /* background-color: red; */
}

.modelosCont {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
}

.modeloCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #67A23A;
}

.modeloInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* width: 102px; */
    /* height: 31px; */
    text-align: center;
    padding-left: 0.2em;
}

@media (min-width: 768px) {
    .modelosCont {
        display: flex;
        justify-content: space-between;
        padding: 80px 0;
        cursor: pointer;
        /* background-color: red; */
        width: 100%;
    }

    .section h3 {
        font-size: 32px;
    }

    .modeloCard {
        font-size: 24px;
        gap: 1em;
    }
    .imgCont {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .imgCont img {
        width: 60%;
    }
    .modeloInfo {
        width: 100%;
        height: 58px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1500px) {
    .modeloCard {
        font-size: 20px;
    }
}