.header {
    width: 100%;
    /* position: relative; */
}
.sliderMobile {
  position: relative;
}

.sliderWeb {
    display: none;
}

.imgCont {
    width: 20%;
    height: auto;
}
.imgCont img {
    width: 100%;
    /* width: auto; */
}

.circles {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    width: 100%;
    color: gray;
}

.sliderMobile .circles {
    font-size: 13px;
}

@media (min-width: 768px) {
    .sliderMobile {
        display: none;
    }
    .sliderWeb {
        display: block;
    }
    .circles {
        padding: 1em 0;
    }
}