.descripContainer {
    width: 100%;
    padding-top: 1em 0;
    padding-top: 1em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.infoContainer {
    width: 90%;
}

.infoContainer h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}
.infoContainer p {
    font-size: 12px;
    margin: 0;
    padding: 1em 0 1.5em 0;
}

.imgContainer {
    width: 100%;
}
.imgContainer img {
    width: 100%;
}

@media (min-width: 768px) {
    .descripContainer {
        width: 90%;
        flex-direction: row;
        gap: 5em;
        padding: 5em 0;
        padding: 0;
        padding-top: 5em;
    }

    .infoContainer h3 {
        font-size: 32px;
    }

    .infoContainer p {
        font-size: 18px;
    }


    .imgContainer img {
        width: 80%;
    }
}