.navbar {
    position: fixed;
    z-index: 500;
    width: 100%;
    /* background-color: orange; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15vh;
    padding: 1em 0;
    box-shadow: 0 0.5px 10px black;
    background-color: #fff;
  }
  .navContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    width: 90%;
  }
  .flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1em;
}
.alignMenu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
}
.imgContainer {
    /* background-color: blue; */

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .imgContainer img {
    /* width: 100%; */
    cursor: pointer;
    max-width: 50%;
    object-fit: cover;
  }
  .iconContainer {
    width: 100%;
    /* background-color: blue; */
    font-size: 3em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .iconWs {
    width: 100%;
    color: #25d366;
    object-fit: cover;
    height: 100%;
    font-size: 3em;
  
  }
  .contenedorLupa {
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 0.2em;
  }
  .iconLupa {
    border-radius: 50%;  
    background-color: #000000;
    color: #fff;
    object-fit: cover;
    width: 100%;
    height: 100%;
    font-size: 0.5em;
  }
  .categorias {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: start;
    gap: 2em;
  }
  .spanCont{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2em;
}

  .mercuryContainer {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .mercuryContainer img {
    max-width: 100%;
    object-fit: cover;
  }
  .categorias a {
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    text-decoration: none;
    color: #000000;
  }

  .navSpace{
    height: 15vh;
    background-color: red;
    padding: 1em 0;
  }

  .categorias span {
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    text-decoration: none;
    color: #000000;
  }

